//引入接口
import { fun_PostAction } from "@/api/index";
// 引入拼接删除id方法
import { fun_GetKeys } from "@/utils/validate";
// 引入三级字典详情弹窗
import ListDrawer from "./component/listDrawer/index.vue";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      dictionaryId: null,
      // 表单字段
      form: {
        entriesName: null, //字典名称
        entriesCode: null //字典编码
      },
      entriesName: "暂无", //
      topEntriesName: "", // 添加二级按钮 上级下拉框显示
      //详情表单字段
      formDetail: {
        entriesName: null //字典名称
      },
      formSearchDetail: [
        {
          type: "input",
          label: "字典项名称：",
          value: "entriesName"
        }
      ],
      // 查询表单
      formSearchList: [
        {
          type: "input",
          label: "字典名称：",
          value: "entriesName"
        },
        {
          type: "input",
          label: "字典编码：",
          value: "entriesCode"
        }
      ],
      // 详情表格基础数据
      tableColumnsDetail: [
        {
          label: "字典项名称",
          prop: "entriesName"
        },
        {
          label: "字典项编码",
          prop: "entriesCode"
        },
        {
          label: "排序",
          prop: "sort"
        },
        {
          label: "操作",
          slotName: "cz"
        }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "字典名称",
          prop: "entriesName"
        },
        {
          label: "字典编码",
          prop: "entriesCode"
        },
        {
          label: "排序",
          prop: "sort"
        }
      ],
      // 表头按钮组
      btnList: [
        {
          name: "添加",
          type: "success",
          icon: "document-add"
        },
        {
          name: "修改",
          type: "primary",
          icon: "edit"
        },
        {
          name: "删除",
          type: "danger",
          icon: "delete"
        }
      ],
      //详情表头按钮组
      btnDetail: [
        {
          name: "添加",
          type: "success",
          icon: "document-add"
        },
        {
          name: "修改",
          type: "primary",
          icon: "edit"
        },
        {
          name: "删除",
          type: "danger",
          icon: "delete"
        }
      ],
      // 表格数据
      tableData: [],
      //详情表格数据
      tableDataDetail: [],
      // 分页
      reqPageDataList: {
        current: 1, // 页码
        size: 10 // 每页条数
        // total: 0 // 总条数
      },
      total: 0, //左边总数
      totals: 0, //右边总数
      // 分页
      reqPageDataDetail: {
        current: 1, // 页码
        size: 10 // 每页条数
        // total: 0 // 总条数
      },
      //左边表单
      formList: {
        dictionaryId: "0",
        entriesName: null,
        entriesCode: null,
        cancelFlag: null, //作废标志
        sort: null //排序,
      },
      // 表单基础数据
      formConfigList: [
        {
          label: "字典名称：",
          value: "entriesName",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入用户名称",
            trigger: "blur"
          }
        },
        {
          label: "字典编码：",
          value: "entriesCode",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入字典编码",
            trigger: "blur"
          }
        },
        {
          label: "排序：",
          value: "sort",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入排序",
            trigger: "blur"
          }
        },
        {
          label: "作废标志：",
          value: "cancelFlag",
          type: "select",
          required: true,
          rule: {
            required: true,
            message: "请输入作废标志",
            trigger: "blur"
          },
          queryParams: {
            sqlId: "201907041546460002",
            dictionaryId: "ca7aa4c6-d33c-4b4d-9ba7-b06dc67e46b9"
          }
        }
      ],
      //右边表单
      dictDetail: {
        cancelFlag: null, //作废标志
        entriesCode: null, //字典项编码
        entriesName: null, //字典名称
        dictionaryId: null, //字典id
        sort: null //排序
      },
      //详情表单数据
      formConfigDetail: [
        {
          label: "上级字典：",
          value: "dictionaryId",
          type: "select",
          disabled: true,
          // defaultSelected: true, // 是否默认选中第一个
          // queryParams: {
          //   sqlId: "201907041546460002"
          // }
        },
        {
          label: "字典项名称：",
          value: "entriesName",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入字典项名称",
            trigger: "blur"
          }
        },
        {
          label: "字典项编码：",
          value: "entriesCode",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入字典编码",
            trigger: "blur"
          }
        },
        {
          label: "排序：",
          value: "sort",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入排序",
            trigger: "blur"
          }
        },
        {
          label: "作废标志：",
          value: "cancelFlag",
          type: "select",
          required: true,
          rule: {
            required: true,
            message: "请输入作废标志",
            trigger: "change"
          },
          queryParams: {
            sqlId: "201907041546460002",
            dictionaryId: "ca7aa4c6-d33c-4b4d-9ba7-b06dc67e46b9"
          }
        }
      ],
      //字典表单
      dialogVisible: false,
      //详情表单
      dialogVisibleDetail: false,
      // 后台返回错误对象
      errRules: {
        sort: null
      },
      //详情后台返回对象
      errRulesDetail: {
        sort: null
      },
      loadingList: false,
      loadingDetail: false,
      multipleSelectionList: [],
      multipleSelectionDetail: []
    };
  },
  components: {
    ListDrawer
  },
  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();
  },
  // 自定义方法
  methods: {
    /**
     * 获取一览表格数据
     * @author 付翔
     */
    fun_GetList() {
      // 执行获取列表接口
      fun_PostAction("dataDictionary/list", {
        ...this.form,
        ...this.reqPageDataList,
        dictionaryId: 0
      }).then(res => {
        // 解析后台返回的结果集，判断是否成功
        if (res.data.result) {
          // 成功的场景
          this.tableData = res.data.data.records;
          // 展示分页总条数
          this.total = res.data.data.total;
        } else {
          // 请求失败，弹出失败msg的消息提示
          this.$message.error(res.data.msg);
        }
      });
    },
    /**
     * 获取详情表格数据
     * @author 付翔
     */
    fun_GetDetail(e) {
      //执行接口
      fun_PostAction("dataDictionary/list", {
        ...this.formDetail,
        ...this.reqPageDataDetail,
        dictionaryId: e
      }).then(res => {
        // 解析后台返回的结果集，判断是否成功
        if (res.data.result) {
          // 成功的场景
          // 将请求到的table数据渲染到table
          this.tableDataDetail = res.data.data.records;
          // // 展示分页总条数
          this.totals = res.data.data.total;
        } else {
          // 请求失败的场景
          // 请求失败，loading消失
          this.loading = false;
          // 请求失败，弹出失败msg的消息提示
          this.$message.error(res.data.msg);
        }
      });
      // 执行获取列表接口
    },
    /**
     * 点击按钮事件
     * @params e 按钮name
     * @author 付翔
     */
    fun_click(e) {
      switch (e) {
        case "添加":
          // 打开抽屉
          this.dialogVisible = true;
          this.formList.id = null;
          break;
        case "修改":
          // 调用修改方法
          this.fun_EditList();
          break;
        default:
          // 调用删除方法
          this.fun_DelList();
      }
    },
    //字典添加表单
    fun_SubmitFormList() {
      //获取表单的元素并执行框架效验方法
      this.$refs.formDList.$refs.enInform.validate(valid => {
        //是否符合效验规则
        if (valid) {
          //执行提交方法
          fun_PostAction(
            "dataDictionary/saveOrUpdateDataDictionary",
            this.formList
          ).then(result => {
            //判断
            if (result.data.result) {
              // 提示信息
              this.$message.success(result.data.msg);
              // 关闭遮罩
              this.dialogVisible = false;
              // 执行一览表格方法
              this.fun_GetList();
            } else {
              // 如果返回错误消息有值
              if (result.data.validation) {
                //随机数
                this.errRules = Math.random();
                this.$nextTick(() => {
                  // 提示
                  this.$message.error("请按提示修改");
                  // 赋值错误消息
                  this.errRules = result.data;
                });
              } else {
                this.$message.error(result.data.msg);
              }
            }
          });
        }
      }); // 执行判断条件
    },
    /**
     * 左边字典勾选方法
     * @params
     * @author 付翔
     */
    fun_HandleSelectionChangeList(e) {
      //赋值
      this.multipleSelectionList = e;
    },
    /**
     * 右边字典勾选方法
     * @params
     * @author 付翔
     */
    fun_HandleSelectionChangeDetail(e) {
      //赋值
      this.multipleSelectionDetail = e;
    },
    /**
     * 左边字典删除方法
     * @params
     * @author 付翔
     */
    fun_DelList() {
      // 执行判断条件选中长度为1
      if (this.multipleSelectionList.length < 1) {
        // 提示消息
        this.$alert("请至少选择一条数据！", "warning");
      } else {
        // 获取删除id
        const keys = fun_GetKeys(this.multipleSelectionList);
        // 提示消息
        this.$confirm("是否确定删除此数据", {
          // 提示类型
          type: "warning"
        }).then(() => {
          // 执行删除方法
          fun_PostAction(
            "dataDictionary/deleteDataDictionary?keys=" + keys
          ).then(result => {
            if (result.data.result) {
              // 重载表格
              this.fun_GetList();
              // 提示消息
              this.$message.success(result.data.msg);
              //右边表格清空
              this.tableDataDetail = [];
              this.entriesName = "暂无";
              //
            } else {
              this.$message.error(result.data.msg);
            }
          });
        });
      }
    },
    /**
     * 左边列表修改方法
     * @params e 按钮name
     * @author 付翔
     */
    fun_EditList() {
      // 判断是否勾选
      if (this.multipleSelectionList && this.multipleSelectionList.length > 0) {
        // 勾选超过1条
        if (this.multipleSelectionList.length > 1) {
          // 提示，并清除选中数据
          this.$alert("只能选择一条数据！", "warning").then(() => {
            // 清除选中数据
            this.$refs.formDList.$refs.enInform.clearSelection();
          });
        } else {
          // 打开抽屉
          this.dialogVisible = true;
          // let id = "";
          // this.multipleSelectionList.forEach(item => {
          //   // 如果id为空
          //   if (id === "") {
          //     /// 赋值
          //     id = item.id;
          //   } else {
          //     /// 赋值
          //     id = id + ";" + item.id;
          //   }
          // });
          fun_PostAction(
            "dataDictionary/dataDictionaryDetail?id=" +
              this.multipleSelectionList[0].id
          ).then(res => {
            this.formList = res.data.data;
          });
        }
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    //关闭时回调
    handleCloseList() {
      this.dialogVisible = false;
      // 清除表单验证和数据
      this.$refs.formDList.$refs.enInform.resetFields();
      this.$refs.formDList.$refs.formItem[3].$children[1].value = null;
    },
    //详情关闭时回调
    handleCloseDetail() {
      this.dialogVisibleDetail = false;
      // 清除表单验证和数据
      this.$refs.formdDetil.$refs.enInform.resetFields();
      // 清空是否固定角色下拉框
      this.$refs.formdDetil.$refs.formItem[4].$children[1].value = null;
    },
    // 打开时的回调
    handleOpenDetail(){
      // 选中上级
      this.$refs.formdDetil.$refs.formItem[0].$children[1].value = this.entriesName;
    },
    /**
     * 详情点击按钮事件
     * @params e 按钮name
     * @author 付翔
     */
    fun_clickDetail(e) {
      switch (e) {
        case "添加":
          if (this.entriesName == "暂无") {
            this.$alert("请选择左侧数据字典！", "warning");
          } else {
            // 打开抽屉
            this.dialogVisibleDetail = true;
            this.dictDetail.id = null;
            this.dictDetail.dictionaryId = this.dictionaryId;
            this.entriesName = this.topEntriesName;
          }
          break;
        case "修改":
          // 调用修改方法
          this.fun_EditDetail();
          break;
        default:
          // 调用删除方法
          this.fun_DelDetail();
      }
    },
    /**
     * 详情新增子项按钮事件
     * @params e 按钮name
     * @author 付翔
     */
    fun_AddDetail(e) {
      // 打开抽屉
      this.dialogVisibleDetail = true;
      this.dictDetail.dictionaryId = e.id;
      // 选中上级
      this.entriesName = e.entriesName;
      this.dictDetail.id = null;
    },
    /**
     * 详情列表按钮事件
     * @author 付翔
     */
    fun_DetailList(e) {
      // 打开抽屉
      this.$refs.listDrawer.tableState = true;
      this.$refs.listDrawer.fun_GetList(e.id);
      this.$refs.listDrawer.fun_SelectDefault(e.entriesName);
    },
    /**
     * @description 选择下拉框
     * @param {e} 选中的value值
     * @author 付翔
     */
    fun_GetSelectValList(e) {
      // 回显
      this.formList.cancelFlag = e;
    },
    /**
     * @description 选择下拉框
     * @param {e} 选中的value值
     * @author 付翔
     */
    fun_GetSelectValDetail(e) {
      // 回显
      this.dictDetail.cancelFlag = e;
    },
    /**
     * 右边列表查看方法
     * @params e 按钮name
     * @author 付翔
     */
    fun_EditDetail() {
      // 判断是否勾选
      if (
        this.multipleSelectionDetail &&
        this.multipleSelectionDetail.length > 0
      ) {
        // 勾选超过1条
        if (this.multipleSelectionDetail.length > 1) {
          // 提示，并清除选中数据
          this.$alert("只能选择一条数据！", "warning").then(() => {
            // 清除选中数据
            this.$refs.formdDetil.$refs.errRulesDetail.clearSelection();
          });
        } else {
          // 打开抽屉
          this.dialogVisibleDetail = true;
          // 上一级还是交易中心
          this.entriesName = this.topEntriesName;
          fun_PostAction(
            "dataDictionary/dataDictionaryDetail?id=" +
              this.multipleSelectionDetail[0].id
          ).then(res => {
            this.dictDetail = res.data.data;
          });
        }
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    /**
     * 二级三级字典保存公共方法
     * @author 付翔
     */
    fun_ComSubmitDetail(id) {
      //获取表单的元素并执行框架效验方法
      this.$refs.formdDetil.$refs.enInform.validate(valid => {
        //是否符合效验规则
        if (valid) {
          //执行提交方法
          fun_PostAction(
            "dataDictionary/saveOrUpdateDataDictionary",
            this.dictDetail
          ).then(result => {
            if (result.data.result) {
              // 提示信息
              this.$message.success(result.data.msg);
              // 关闭遮罩
              this.dialogVisibleDetail = false;
              // 执行一览表格方法
              this.fun_GetDetail(id);
            } else {
              // 如果返回错误消息有值
              if (result.data.validation) {
                this.errRulesDetail = Math.random();
                this.$nextTick(() => {
                  // 提示
                  this.$message.error("请按提示修改");
                  // 赋值错误消息
                  this.errRulesDetail = result.data.validation;
                });
              } else {
                this.$message.error(result.data.msg);
              }
            }
          });
        }
      });
    },
    /**
     * 右边列表添加修改
     * @author 付翔
     */
    fun_SubmitFormDetail() {
      // 二级/三级字典保存
      this.fun_ComSubmitDetail(this.dictionaryId);
    },
    /**
     * 右边列表删除
     * @params e 按钮name
     * @author 付翔
     */
    fun_DelDetail() {
      // 执行判断条件选中长度为1
      if (this.multipleSelectionDetail.length < 1) {
        // 提示消息
        this.$alert("请至少选择一条数据！", "warning");
      } else {
        // 获取删除id
        const keys = fun_GetKeys(this.multipleSelectionDetail);
        // 提示消息
        this.$confirm("是否确定删除此数据", {
          // 提示类型
          type: "warning"
        }).then(() => {
          // 执行删除方法
          fun_PostAction(
            "dataDictionary/deleteDataDictionary?keys=" + keys
          ).then(result => {
            if (result.data.result) {
              // 重载表格
              this.fun_GetDetail(this.dictionaryId);
              // 提示消息
              this.$message.success(result.data.msg);
            } else {
              this.$message.error(result.data.msg);
            }
          });
        });
      }
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 付翔
     */
    fun_LimitChangeList(e) {
      this.reqPageDataList.size = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 付翔
     */
    fun_PageChangeList(e) {
      this.reqPageDataList.current = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 付翔
     */
    fun_LimitChangeDetail(e) {
      this.reqPageDataDetail.size = e;
      // 获取一览表格数据
      this.fun_GetDetail(this.dictionaryId);
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 付翔
     */
    fun_PageChangeDetail(e) {
      this.reqPageDataDetail.current = e;
      // 获取一览表格数据
      this.fun_GetDetail(this.dictionaryId);
    },
    /**
     * @description 点击字典表格查详情表格数据
     * @param {e} 选中的value值
     * @author 付翔
     */
    fun_rowclick(e) {
      // 变量转换
      this.entriesName = e.entriesName;
      this.topEntriesName = e.entriesName;
      // 执行查看详情列表方法
      this.fun_GetDetail(e.id);
      this.dictDetail.dictionaryId = e.id;
      // 字典id赋值
      this.dictionaryId = e.id;
      // 重置数据字典明细页码
      this.reqPageDataDetail.current = 1;
      // 重置数据字典明细每页显示条数
      this.reqPageDataDetail.size = 10;
    },
    /**
     * @description list搜索
     * @param  选中的value值
     * @author 付翔
     */
    fun_SearchList() {
      this.fun_GetList();
    },
    /**
     * @description 详情搜索
     * @param  选中的value值
     * @author 付翔
     */
    fun_SearchDetail() {
      if (this.dictionaryId === null) {
        // 提示消息
        this.$alert("请选择左边字典！", "warning");
      } else this.fun_GetDetail(this.dictionaryId);
    }
  }
};
