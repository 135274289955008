// 引入接口
import { fun_PostAction } from "@/api/index";
// 引入拼接删除id方法
import { fun_GetKeys } from "@/utils/validate";
export default {
  data: () => {
    return {
      tableState: false,
      gridData: [],
      entriesName: "",
      tableColumns: [
        {
          label: "字典项名称",
          prop: "entriesName"
        },
        {
          label: "字典项编码",
          prop: "entriesCode"
        },
        {
          label: "排序",
          prop: "sort"
        }
      ],
      // 分页
      reqPageData: {
        current: 1, // 页码
        size: 10 // 每页条数
      },
      total: 0,
      // 表头按钮组
      btnList: [
        {
          name: "添加",
          type: "success",
          icon: "document-add"
        },
        {
          name: "修改",
          type: "primary",
          icon: "edit"
        },
        {
          name: "删除",
          type: "danger",
          icon: "delete"
        }
      ],
      multipleSelectionList: [],
      dialogVisible: false,
      // 三级字典表单
      formList: {
        dictionaryId: null,
        entriesName: null,
        entriesCode: null,
        cancelFlag: null, //作废标志
        sort: null //排序,
      },
      // 表单基础数据
      formConfigList: [
        {
          label: "上级字典：",
          value: "dictionaryId",
          type: "select",
          disabled: true,
          // defaultSelected: true, // 是否默认选中第一个
          // queryParams: {
          //   sqlId: "201907041546460002"
          // }
        },
        {
          label: "字典名称：",
          value: "entriesName",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入用户名称",
            trigger: "blur"
          }
        },
        {
          label: "字典编码：",
          value: "entriesCode",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入字典编码",
            trigger: "blur"
          }
        },
        {
          label: "排序：",
          value: "sort",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入排序",
            trigger: "blur"
          }
        },
        {
          label: "作废标志：",
          value: "cancelFlag",
          type: "select",
          required: true,
          rule: {
            required: true,
            message: "请输入作废标志",
            trigger: "blur"
          },
          queryParams: {
            sqlId: "201907041546460002",
            dictionaryId: "ca7aa4c6-d33c-4b4d-9ba7-b06dc67e46b9"
          }
        }
      ],
      loading: false
    };
  },
  methods: {
    async fun_GetList(id) {
      this.formList.dictionaryId = id;
      let res = await fun_PostAction("/dataDictionary/list", {
        ...this.reqPageData,
        dictionaryId: this.formList.dictionaryId
      });
      const { result, data } = res.data;
      if (result) {
        const { records, total } = data;
        this.gridData = records;
        this.total = total;
      }
    },
    /**
     * 点击按钮事件
     * @params e 按钮name
     * @author 付翔
     */
    fun_click(e) {
      switch (e) {
        case "添加":
          // 打开抽屉
          this.dialogVisible = true;
          // this.formList.id = null;
          break;
        case "修改":
          // 调用修改方法
          this.fun_Edit();
          break;
        default:
          // 调用删除方法
          this.fun_Del();
      }
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 付翔
     */
    fun_LimitChangeList(e) {
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 付翔
     */
    fun_PageChangeList(e) {
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 列表勾选方法
     * @params
     * @author 付翔
     */
    fun_HandleSelectionChangeList(e) {
      //赋值
      this.multipleSelectionList = e;
    },
    //关闭时回调
    handleCloseList() {
      this.dialogVisible = false;
      // 清除表单验证和数据
      this.$refs.formDList.$refs.enInform.resetFields();
      this.$refs.formDList.$refs.formItem[4].$children[1].value = null;
      this.$refs.formDList.$refs.formItem[0].$children[1].value = null;
    },
    handleOpenList(){
      this.$refs.formDList.$refs.formItem[0].$children[1].value = this.entriesName;
    },
    // 父页面调用子页面
    fun_SelectDefault(e){
      this.entriesName = e;
    },
    /**
     * @description 选择下拉框
     * @param {e} 选中的value值
     * @author 付翔
     */
    fun_GetSelectValList(e) {
      // 回显
      this.formList.cancelFlag = e;
    },
    fun_SubmitFormList() {
      //获取表单的元素并执行框架效验方法
      this.$refs.formDList.$refs.enInform.validate(valid => {
        //是否符合效验规则
        if (valid) {
          //执行提交方法
          fun_PostAction(
            "dataDictionary/saveOrUpdateDataDictionary",
            this.formList
          ).then(result => {
            if (result.data.result) {
              // 提示信息
              this.$message.success(result.data.msg);
              // 关闭遮罩
              this.dialogVisible = false;
              // 执行一览表格方法
              this.fun_GetList(this.formList.dictionaryId);
            } else {
              // 如果返回错误消息有值
              // if (result.data.validation) {
              //   this.errRulesDetail = Math.random();
              //   this.$nextTick(() => {
              //     // 提示
              //     this.$message.error("请按提示修改");
              //     // 赋值错误消息
              //     this.errRulesDetail = result.data.validation;
              //   });
              // } else {
              this.$message.error(result.data.msg);
              // }
            }
          });
        }
      });
    },
    fun_Del() {
      // 执行判断条件选中长度为1
      if (this.multipleSelectionList.length < 1) {
        // 提示消息
        this.$alert("请选择一条数据！", "warning");
      } else {
        // 获取删除id
        const keys = fun_GetKeys(this.multipleSelectionList);
        // 提示消息
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          // 执行删除方法
          fun_PostAction(
            "dataDictionary/deleteDataDictionary?keys=" + keys
          ).then(result => {
            if (result.data.result) {
              // 重载表格
              this.fun_GetList(this.formList.dictionaryId);
              // 提示消息
              this.$message.success(result.data.msg);
            } else {
              this.$message.error(result.data.msg);
            }
          });
        });
      }
    },
    /**
     * 修改
     * @author 付翔
     */
    fun_Edit() {
      // 判断是否勾选
      if (this.multipleSelectionList && this.multipleSelectionList.length > 0) {
        // 勾选超过1条
        if (this.multipleSelectionList.length > 1) {
          // 提示，并清除选中数据
          this.$alert("只能选择一条数据！", "warning").then(() => {
            // 清除选中数据
            this.$refs.formdDetil.$refs.errRulesDetail.clearSelection();
          });
        } else {
          // 打开抽屉
          this.dialogVisible = true;
          fun_PostAction(
            "dataDictionary/dataDictionaryDetail?id=" +
              this.multipleSelectionList[0].id
          ).then(res => {
            this.formList = res.data.data;
          });
        }
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    }
  }
};
